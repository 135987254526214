export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts"
      });
      return addressArray[0];
    } catch (err) {
      return "";
    }
  } else return "";
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts"
      });
      if (addressArray.length > 0) {
        return addressArray[0];
      } else {
        return "";
      }
    } catch (err) {
      return "";
    }
  } else return "";
};
