import React, { useState, useEffect } from "react";
import BoredPunk from "../contracts/BoredPunk.js";
import OpenSea from "../contracts/OpenSea.js";
import { connectWallet, getCurrentWalletConnected } from "../utils/connect.js";
import {getMapping, getOpenSeaTokenId, getLeaf, getProof, getRoot } from "../utils/swap.js";
import connect from "../assets/CONNECT.png";
import swap from "../assets/SWAP.png";

function TokenSwap(props) {
  const [leaf, setLeaf] = useState("");
  const [proof, setProof] = useState("");
  const [input, setInput] = useState("");
  const [walletAddress, setWallet] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [openseaTokenId, setopenseaTokenId] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setWallet(walletResponse);
  };

  const mint = async () => {
    await setApproval();

    try {
      let result = await BoredPunk.methods
        .mintAndBurn(openseaTokenId, input, leaf, proof)
        .send({ from: window.ethereum.selectedAddress, gasLimit: 410000 });
      setTransactionStatus(result.transactionHash);
    } catch (e) {
      setTransactionStatus("Error. Please try again.");
    }
  };

  const setApproval = async () => {
    try {
      setTransactionStatus(
        "Please wait while this transaction is confirmed and MetaMask will present a new transaction to mint your new Bored Punk."
      );

      await OpenSea.methods
        .setApprovalForAll("0x488a85d21aC95c9BB0cdaa0d2bFa427fceA88D1E", true)
        .send({ from: window.ethereum.selectedAddress, gasLimit: 100000 });
    } catch (e) {
      setTransactionStatus("Error. Please try again.");
    }
  };

  const addWalletListener = async () => {
    if (window.ethereum) {
      try {
        window.ethereum.on("accountsChanged", accounts => {
          if (accounts.length > 0) setWallet(accounts[0]);
          else setWallet("");
        });
      } catch (e) {
        console.log("Error obtaining changed account.");
      }
    }
  };

  const handleChange = async event => {
    try {
      setInput(event.target.value);
      let openSeaTokenId = await getOpenSeaTokenId(event.target.value);
      if (openSeaTokenId) {
        setopenseaTokenId(openSeaTokenId);
      }

      let leaf = await getLeaf(event.target.value);
      if (leaf) {
        setLeaf(leaf);

        let proof = await getProof(leaf);
        if (proof) setProof(proof);
      }
    } catch (e) {
      console.log("Error obtaining punk bored info.");
    }
  };

  const setRoot = async () => {
    let root = await getRoot();

    await BoredPunk.methods.setMerkleRoot(root).send({
      from: window.ethereum.selectedAddress
    });
  };

  useEffect(async () => {
    try {
      const address = await getCurrentWalletConnected();
      setWallet(address);

      const totalSupply = await BoredPunk.methods.totalSupply().call();
      setTotalSupply(totalSupply);

      addWalletListener();
    } catch (e) {
      console.log("Error setting up bored page.");
    }
  }, []);

  return (
    <div className={props.show ? "swap" : "hide"}>
      <div className="swap-info">
        <h1> Perks of Migration: </h1>
        <p>
          > Each and every new Bored Punk will have a share in the club’s
          revolutionary on-chain royalty sharing system, effective immediately
          after the swap, where 5% of all sales get redistributed between club
          members as long as the Ethereum blockchain exists
        </p>
        <p>
          > You’ll get an industry standard ERC-721 version of your Bored Punk
          with IPFS backed metadata
        </p>
        <p>
          > All the new Bored Punks will have two extra trait categories added
          to them (backgrounds / OG punk attributes)
        </p>
        <p>
          > Your Bored Punks will be minted in your name/wallet address as their
          creator
        </p>
      </div>
      <div className="swap-info">
        <h1> Swap Timeframe: </h1>
        <p>
          > No need to rush. The swap will be available until all Bored Punks
          have been swapped.
        </p>
        <p>
          > However, the sooner you swap, the sooner you can begin benefiting
          from the new contract’s perks
        </p>
      </div>

      <div className="swap-info">
        <h1> Instructions: </h1>
        <p>> Input the number of the Bored Punk you own.</p>
        <p>
          > Please note that if you attempt to swap a Bored Punk you do not own
          the transaction will fail and the team will not reimburse your gas
          fees. These funds will be lost.
        </p>

        <p>> Hit the Swap button.</p>
        <p>
          > There will be 2 transactions for each Bored Punk. The first sets
          approval for transferring the old item to a burn address and the
          second mints your new Bored Punk.
        </p>
        <p>
          > Please be careful with Punk Numbers and ensuring you sign on not one
          but two transactions.
        </p>

        <p>> Enjoy your new Bored Punk.</p>
      </div>

      <div className="wallet">
        {walletAddress.length == 0 ? (
          <img
            className="connect-button"
            src={connect}
            onClick={connectWalletPressed}
          />
        ) : (
          <p>{String(walletAddress)}</p>
        )}
      </div>

      <input
        className="swap-input"
        value={input}
        onChange={handleChange}
        placeholder="Bored Punk Number"
      />

      <img className="swap-button" src={swap} alt="swap" onClick={mint} />

      <span className="center">
        <p>{transactionStatus}</p>
      </span>
    </div>
  );
}

export default TokenSwap;

// <button onClick={setRoot}>Set Root</button>
