import React, { useState, useEffect } from "react";
import oneMobile from "./assets/1-MOBILE.png";
import twoMobile from "./assets/2-MOBILE.png";
import threeMobile from "./assets/3-MOBILE.png";
import oneDesktop from "./assets/1-DESKTOP.png";
import twoDesktop from "./assets/2-DESKTOP.png";
import threeDesktop from "./assets/3-DESKTOP.png";
import TokenSwap from "./components/TokenSwap.js";
import "./App.css";

function App() {
  const [show, setShow] = useState(false);

  const handleClick = e => setShow(!show);

  return (
    <div className="app">
        <img className="desktop one"  src={ show ?  threeDesktop : oneDesktop} alt="one" onClick={handleClick}/>
        <img className="mobile" src={ show ?  threeMobile :oneMobile} alt="one"  onClick={handleClick}/>
        <TokenSwap show={show}/>
        <img className="mobile" src={twoMobile} alt="two" />
        <img className="desktop"  src={twoDesktop} alt="two" />
    </div>
  );
}

export default App;
